import { Fragment } from 'react';

import { cn } from '@/lib/utils';

import type { ReactNode } from 'react';

const Skeleton = ({
  className,
  items = 1,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { items?: number }) => {
  return Array.from({ length: items }).map((_, i) => (
    <div
      // eslint-disable-next-line @eslint-react/no-array-index-key, react/no-array-index-key
      key={i}
      className={cn(
        'animate-pulse rounded-md bg-gray-light-200 dark:bg-gray-dark-300',
        className,
      )}
      {...props}
    />
  ));
};

const SkeletonFragment = ({
  items = 1,
  children,
}: {
  items?: number;
  children?: ReactNode;
}) =>
  Array.from({ length: items }).map((_, i) => (
    <Fragment
      // eslint-disable-next-line react/no-array-index-key, @eslint-react/no-array-index-key
      key={i}
    >
      {children}
    </Fragment>
  ));

export { Skeleton, SkeletonFragment };
