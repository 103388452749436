import type { Category, Tag } from '@/hooks/useComprehensiveListings';

interface TagWithType extends Tag {
  type: 'tag' | 'style';
}

interface CategoryWithType extends Category {
  tag: TagWithType[];
}

export const staticComprehensiveListingsSFX: CategoryWithType[] = [
  {
    id: 1,
    name: 'Nature',
    emoji: '🌳',
    order: 1,
    tag: [
      { name: 'beach', type: 'tag', slug: '/sfx/tag/beach' },
      { name: 'bird nature', type: 'tag', slug: '/sfx/tag/bird-nature' },
      { name: 'forest', type: 'style', slug: '/browse/sfx/forest' },
      { name: 'jungle', type: 'tag', slug: '/sfx/tag/jungle' },
      { name: 'nature', type: 'tag', slug: '/sfx/tag/nature' },
      { name: 'ocean', type: 'style', slug: '/browse/sfx/ocean' },
      { name: 'ocean waves', type: 'tag', slug: '/sfx/tag/ocean-waves' },
      { name: 'rainforest', type: 'tag', slug: '/sfx/tag/rainforest' },
      { name: 'river', type: 'tag', slug: '/sfx/tag/river' },
      { name: 'spring', type: 'tag', slug: '/sfx/tag/spring' },
      { name: 'waterfall', type: 'tag', slug: '/sfx/tag/waterfall' },
      { name: 'wave', type: 'tag', slug: '/sfx/tag/wave' },
      { name: 'woodland', type: 'style', slug: '/browse/sfx/woodland' },
    ],
  },
  {
    id: 2,
    name: 'Animals',
    emoji: '🐒',
    order: 2,
    tag: [
      { name: 'animals', type: 'tag', slug: '/sfx/tag/animals' },
      { name: 'bird nature', type: 'tag', slug: '/sfx/tag/bird-nature' },
      { name: 'bear', type: 'tag', slug: '/sfx/tag/bear' },
      { name: 'bear growl', type: 'tag', slug: '/sfx/tag/bear-growl' },
      { name: 'bee', type: 'tag', slug: '/sfx/tag/bee' },
      { name: 'bee buzz', type: 'tag', slug: '/sfx/tag/bee-buzz' },
      { name: 'bird', type: 'tag', slug: '/sfx/tag/bird' },
      { name: 'birds chirping', type: 'tag', slug: '/sfx/tag/birds-chirping' },
      { name: 'cat', type: 'tag', slug: '/sfx/tag/cat' },
      { name: 'cat meow', type: 'tag', slug: '/sfx/tag/cat-meow' },
      { name: 'chicken', type: 'tag', slug: '/sfx/tag/chicken' },
      {
        name: 'chicken clucking',
        type: 'tag',
        slug: '/sfx/tag/chicken-clucking',
      },
      { name: 'cicada', type: 'tag', slug: '/sfx/tag/cicada' },
      { name: 'cougar', type: 'tag', slug: '/sfx/tag/cougar' },
      { name: 'cow', type: 'tag', slug: '/sfx/tag/cow' },
      { name: 'cow moo', type: 'tag', slug: '/sfx/tag/cow-moo' },
      { name: 'cricket', type: 'tag', slug: '/sfx/tag/cricket' },
      { name: 'crow', type: 'tag', slug: '/sfx/tag/crow' },
      { name: 'dinosaur', type: 'tag', slug: '/sfx/tag/dinosaur' },
      { name: 'dog', type: 'tag', slug: '/sfx/tag/dog' },
      { name: 'dog barking', type: 'tag', slug: '/sfx/tag/dog-barking' },
      { name: 'dolphin', type: 'tag', slug: '/sfx/tag/dolphin' },
      { name: 'donkey', type: 'tag', slug: '/sfx/tag/donkey' },
      { name: 'duck', type: 'tag', slug: '/sfx/tag/duck' },
      { name: 'eagle', type: 'tag', slug: '/sfx/tag/eagle' },
      { name: 'elephant', type: 'tag', slug: '/sfx/tag/elephant' },
      {
        name: 'elephant trumpeting',
        type: 'tag',
        slug: '/sfx/tag/elephant-trumpeting',
      },
      { name: 'fly', type: 'tag', slug: '/sfx/tag/fly' },
      { name: 'frog', type: 'tag', slug: '/sfx/tag/frog' },
      { name: 'goat', type: 'tag', slug: '/sfx/tag/goat' },
      { name: 'goose', type: 'tag', slug: '/sfx/tag/goose' },
      { name: 'goose honk', type: 'tag', slug: '/sfx/tag/goose-honk' },
      { name: 'gorilla', type: 'tag', slug: '/sfx/tag/gorilla' },
      { name: 'hawk', type: 'tag', slug: '/sfx/tag/hawk' },
      { name: 'horse', type: 'tag', slug: '/sfx/tag/horse' },
      { name: 'horse neigh', type: 'tag', slug: '/sfx/tag/horse-neigh' },
      { name: 'horse whinny', type: 'tag', slug: '/sfx/tag/horse-whinny' },
      { name: 'insects', type: 'tag', slug: '/sfx/tag/insects' },
      { name: 'lion', type: 'tag', slug: '/sfx/tag/lion' },
      { name: 'lion roar', type: 'tag', slug: '/sfx/tag/lion-roar' },
      { name: 'meow', type: 'tag', slug: '/sfx/tag/meow' },
      { name: 'monkey', type: 'tag', slug: '/sfx/tag/monkey' },
      { name: 'owl', type: 'tag', slug: '/sfx/tag/owl' },
      { name: 'pig', type: 'tag', slug: '/sfx/tag/pig' },
      { name: 'pigs', type: 'tag', slug: '/sfx/tag/pigs' },
      { name: 'puppy', type: 'tag', slug: '/sfx/tag/puppy' },
      { name: 'quack', type: 'tag', slug: '/sfx/tag/quack' },
      { name: 'rattlesnake', type: 'tag', slug: '/sfx/tag/rattlesnake' },
      { name: 'rooster', type: 'tag', slug: '/sfx/tag/rooster' },
      { name: 'sabor', type: 'tag', slug: '/sfx/tag/sabor' },
      { name: 'seagull', type: 'tag', slug: '/sfx/tag/seagull' },
      { name: 'shark', type: 'tag', slug: '/sfx/tag/shark' },
      { name: 'sheep', type: 'tag', slug: '/sfx/tag/sheep' },
      { name: 'snake', type: 'tag', slug: '/sfx/tag/snake' },
      { name: 't rex', type: 'tag', slug: '/sfx/tag/t-rex' },
      { name: 'tiger', type: 'tag', slug: '/sfx/tag/tiger' },
      { name: 'tiger roar', type: 'tag', slug: '/sfx/tag/tiger-roar' },
      { name: 'turkey', type: 'tag', slug: '/sfx/tag/turkey' },
      { name: 'velociraptor', type: 'tag', slug: '/sfx/tag/velociraptor' },
      { name: 'wolf', type: 'tag', slug: '/sfx/tag/wolf' },
      { name: 'wolf growl', type: 'tag', slug: '/sfx/tag/wolf-growl' },
      { name: 'zebra', type: 'tag', slug: '/sfx/tag/zebra' },
    ],
  },
  {
    id: 3,
    name: 'Alarm',
    emoji: '🚨',
    order: 3,
    tag: [
      { name: 'air raid siren', type: 'tag', slug: '/sfx/tag/air-raid-siren' },
      { name: 'air siren', type: 'tag', slug: '/sfx/tag/air-siren' },
      { name: 'alarm', type: 'tag', slug: '/sfx/tag/alarm' },
      { name: 'alert', type: 'tag', slug: '/sfx/tag/alert' },
      { name: 'car alarm', type: 'tag', slug: '/sfx/tag/car-alarm' },
      { name: 'nuclear siren', type: 'tag', slug: '/sfx/tag/nuclear-siren' },
      { name: 'siren', type: 'tag', slug: '/sfx/tag/siren' },
      { name: 'tornado siren', type: 'tag', slug: '/sfx/tag/tornado-siren' },
      { name: 'warning siren', type: 'tag', slug: '/sfx/tag/warning-siren' },
    ],
  },
  {
    id: 4,
    name: 'Funny',
    emoji: '😂',
    order: 4,
    tag: [
      { name: 'air horn', type: 'tag', slug: '/sfx/tag/air-horn' },
      { name: 'annoying', type: 'tag', slug: '/sfx/tag/annoying' },
      { name: 'banana slip', type: 'tag', slug: '/sfx/tag/banana-slip' },
      { name: 'bark', type: 'tag', slug: '/sfx/tag/bark' },
      { name: 'boing', type: 'tag', slug: '/sfx/tag/boing' },
      { name: 'boioioing', type: 'tag', slug: '/sfx/tag/boioioing' },
      { name: 'bonk', type: 'tag', slug: '/sfx/tag/bonk' },
      { name: 'bruh', type: 'tag', slug: '/sfx/tag/bruh' },
      { name: 'cartoon', type: 'style', slug: '/browse/sfx/cartoon' },
      { name: 'cartoon bonk', type: 'tag', slug: '/sfx/tag/cartoon-bonk' },
      { name: 'cartoon crash', type: 'tag', slug: '/sfx/tag/cartoon-crash' },
      {
        name: 'cartoon falling',
        type: 'tag',
        slug: '/sfx/tag/cartoon-falling',
      },
      {
        name: 'cartoon man screaming',
        type: 'tag',
        slug: '/sfx/tag/cartoon-man-screaming',
      },
      {
        name: 'cartoon popping',
        type: 'tag',
        slug: '/sfx/tag/cartoon-popping',
      },
      { name: 'cartoon rattle', type: 'tag', slug: '/sfx/tag/cartoon-rattle' },
      {
        name: 'cartoon running',
        type: 'tag',
        slug: '/sfx/tag/cartoon-running',
      },
      {
        name: 'cartoon sleeping',
        type: 'tag',
        slug: '/sfx/tag/cartoon-sleeping',
      },
      {
        name: 'cartoon slipping',
        type: 'tag',
        slug: '/sfx/tag/cartoon-slipping',
      },
      {
        name: 'cartoon snoring',
        type: 'tag',
        slug: '/sfx/tag/cartoon-snoring',
      },
      {
        name: 'cartoon train horn',
        type: 'tag',
        slug: '/sfx/tag/cartoon-train-horn',
      },
      { name: 'clown', type: 'tag', slug: '/sfx/tag/clown' },
      { name: 'comedy', type: 'tag', slug: '/sfx/tag/comedy' },
      { name: 'comic', type: 'tag', slug: '/sfx/tag/comic' },
      { name: 'correct', type: 'style', slug: '/browse/sfx/correct' },
      { name: 'correct answer', type: 'tag', slug: '/sfx/tag/correct-answer' },
      { name: 'duh duh duh', type: 'tag', slug: '/sfx/tag/duh-duh-duh' },
      { name: 'dun', type: 'tag', slug: '/sfx/tag/dun' },
      { name: 'dun dun dun', type: 'tag', slug: '/sfx/tag/dun-dun-dun' },
      { name: 'fail', type: 'style', slug: '/browse/sfx/fail' },
      { name: 'failure', type: 'tag', slug: '/sfx/tag/failure' },
      { name: 'fart meme', type: 'tag', slug: '/sfx/tag/fart-meme' },
      { name: 'funny', type: 'tag', slug: '/sfx/tag/funny' },
      { name: 'funny laugh', type: 'tag', slug: '/sfx/tag/funny-laugh' },
      { name: 'funny meme', type: 'tag', slug: '/sfx/tag/funny-meme' },
      { name: 'funny scream', type: 'tag', slug: '/sfx/tag/funny-scream' },
      { name: 'goofy', type: 'tag', slug: '/sfx/tag/goofy' },
      { name: 'goofy laugh', type: 'tag', slug: '/sfx/tag/goofy-laugh' },
      { name: 'hallelujah', type: 'tag', slug: '/sfx/tag/hallelujah' },
      { name: 'joke', type: 'tag', slug: '/sfx/tag/joke' },
      { name: 'kazoo', type: 'tag', slug: '/sfx/tag/kazoo' },
      { name: 'laugh', type: 'tag', slug: '/sfx/tag/laugh' },
      { name: 'laugh track', type: 'tag', slug: '/sfx/tag/laugh-track' },
      { name: 'laughing', type: 'tag', slug: '/sfx/tag/laughing' },
      { name: 'laughter', type: 'tag', slug: '/sfx/tag/laughter' },
      { name: 'loser', type: 'tag', slug: '/sfx/tag/loser' },
      { name: 'meme', type: 'tag', slug: '/sfx/tag/meme' },
      { name: 'record scratch', type: 'tag', slug: '/sfx/tag/record-scratch' },
      {
        name: 'rooster crowing',
        type: 'tag',
        slug: '/sfx/tag/rooster-crowing',
      },
      { name: 'screech stop', type: 'tag', slug: '/sfx/tag/screech-stop' },
      { name: 'sheesh', type: 'tag', slug: '/sfx/tag/sheesh' },
      { name: 'sitcom', type: 'tag', slug: '/sfx/tag/sitcom' },
      { name: 'squeaky toy', type: 'tag', slug: '/sfx/tag/squeaky-toy' },
      { name: 'stupid', type: 'tag', slug: '/sfx/tag/stupid' },
      { name: 'success', type: 'tag', slug: '/sfx/tag/success' },
      { name: 'ta da', type: 'tag', slug: '/sfx/tag/ta-da' },
      { name: 'triggered', type: 'tag', slug: '/sfx/tag/triggered' },
      { name: 'victory', type: 'tag', slug: '/sfx/tag/victory' },
      { name: 'wah wah wah', type: 'tag', slug: '/sfx/tag/wah-wah-wah' },
      { name: 'what', type: 'style', slug: '/browse/sfx/what' },
      { name: 'win', type: 'tag', slug: '/sfx/tag/win' },
      { name: 'winner', type: 'style', slug: '/browse/sfx/winner' },
      { name: 'winning', type: 'tag', slug: '/sfx/tag/winning' },
      { name: 'wrong', type: 'tag', slug: '/sfx/tag/wrong' },
    ],
  },
  {
    id: 5,
    name: 'Bells & Whistles',
    emoji: '🛎️',
    order: 5,
    tag: [
      { name: 'bell', type: 'tag', slug: '/sfx/tag/bell' },
      { name: 'bell ring', type: 'tag', slug: '/sfx/tag/bell-ring' },
      { name: 'bong', type: 'tag', slug: '/sfx/tag/bong' },
      { name: 'buddhist bell', type: 'tag', slug: '/sfx/tag/buddhist-bell' },
      { name: 'chime', type: 'tag', slug: '/sfx/tag/chime' },
      { name: 'church bell', type: 'tag', slug: '/sfx/tag/church-bell' },
      { name: 'cowbell', type: 'tag', slug: '/sfx/tag/cowbell' },
      { name: 'ding ding ding', type: 'tag', slug: '/sfx/tag/ding-ding-ding' },
      { name: 'doorbell', type: 'tag', slug: '/sfx/tag/doorbell' },
      { name: 'jingle bells', type: 'tag', slug: '/sfx/tag/jingle-bells' },
      { name: 'ringing', type: 'tag', slug: '/sfx/tag/ringing' },
      { name: 'school bell', type: 'tag', slug: '/sfx/tag/school-bell' },
      { name: 'slide whistle', type: 'tag', slug: '/sfx/tag/slide-whistle' },
      { name: 'train whistle', type: 'tag', slug: '/sfx/tag/train-whistle' },
      { name: 'whistle', type: 'tag', slug: '/sfx/tag/whistle' },
      { name: 'whistling', type: 'tag', slug: '/sfx/tag/whistling' },
      { name: 'windchimes', type: 'tag', slug: '/sfx/tag/windchimes' },
    ],
  },
  {
    id: 6,
    name: 'Beeps & Buzzers',
    emoji: '📢',
    order: 6,
    tag: [
      {
        name: 'apartment buzzer',
        type: 'tag',
        slug: '/sfx/tag/apartment-buzzer',
      },
      { name: 'beep', type: 'tag', slug: '/sfx/tag/beep' },
      { name: 'beeper', type: 'tag', slug: '/sfx/tag/beeper' },
      { name: 'beeping', type: 'tag', slug: '/sfx/tag/beeping' },
      { name: 'bleep', type: 'tag', slug: '/sfx/tag/bleep' },
      { name: 'bloop', type: 'tag', slug: '/sfx/tag/bloop' },
      { name: 'blooper', type: 'tag', slug: '/sfx/tag/blooper' },
      { name: 'blooper beep', type: 'tag', slug: '/sfx/tag/blooper-beep' },
      { name: 'blow horn', type: 'tag', slug: '/sfx/tag/blow-horn' },
      { name: 'boop', type: 'tag', slug: '/sfx/tag/boop' },
      { name: 'bullhorn', type: 'tag', slug: '/sfx/tag/bullhorn' },
      { name: 'buzz', type: 'tag', slug: '/sfx/tag/buzz' },
      { name: 'buzzer', type: 'tag', slug: '/sfx/tag/buzzer' },
      { name: 'buzzing', type: 'tag', slug: '/sfx/tag/buzzing' },
      { name: 'car alarm', type: 'tag', slug: '/sfx/tag/car-alarm' },
      { name: 'car horn', type: 'tag', slug: '/sfx/tag/car-horn' },
      { name: 'censor', type: 'tag', slug: '/sfx/tag/censor' },
      { name: 'censor beep', type: 'tag', slug: '/sfx/tag/censor-beep' },
      {
        name: 'digital watch beep',
        type: 'tag',
        slug: '/sfx/tag/digital-watch-beep',
      },
      { name: 'DJ air horn', type: 'tag', slug: '/sfx/tag/DJ-air-horn' },
      { name: 'fog horn', type: 'tag', slug: '/sfx/tag/fog-horn' },
      { name: 'sonar', type: 'tag', slug: '/sfx/tag/sonar' },
      { name: 'train horn', type: 'tag', slug: '/sfx/tag/train-horn' },
      { name: 'truck horn', type: 'tag', slug: '/sfx/tag/truck-horn' },
    ],
  },
  {
    id: 7,
    name: 'Transport',
    emoji: '🚗',
    order: 7,
    tag: [
      { name: 'airplane', type: 'tag', slug: '/sfx/tag/airplane' },
      { name: 'car', type: 'tag', slug: '/sfx/tag/car' },
      { name: 'car alarm', type: 'tag', slug: '/sfx/tag/car-alarm' },
      { name: 'car horn', type: 'tag', slug: '/sfx/tag/car-horn' },
      { name: 'car crash', type: 'tag', slug: '/sfx/tag/car-crash' },
      { name: 'car driving', type: 'tag', slug: '/sfx/tag/car-driving' },
      { name: 'car engine', type: 'tag', slug: '/sfx/tag/car-engine' },
      { name: 'car on gravel', type: 'tag', slug: '/sfx/tag/car-on-gravel' },
      { name: 'car revving', type: 'tag', slug: '/sfx/tag/car-revving' },
      {
        name: 'car screeching and crashing',
        type: 'tag',
        slug: '/sfx/tag/car-screeching-and-crashing',
      },
      { name: 'helicopter', type: 'tag', slug: '/sfx/tag/helicopter' },
      { name: 'motorcycle', type: 'tag', slug: '/sfx/tag/motorcycle' },
      {
        name: 'public transport',
        type: 'tag',
        slug: '/sfx/tag/public-transport',
      },
      { name: 'race car', type: 'tag', slug: '/sfx/tag/race-car' },
      { name: 'road', type: 'tag', slug: '/sfx/tag/road' },
      { name: 'tire screech', type: 'tag', slug: '/sfx/tag/tire-screech' },
      { name: 'traffic', type: 'style', slug: '/browse/sfx/traffic' },
      { name: 'train', type: 'tag', slug: '/sfx/tag/train' },
      { name: 'train horn', type: 'tag', slug: '/sfx/tag/train-horn' },
      { name: 'transport', type: 'tag', slug: '/sfx/tag/transport' },
      {
        name: 'truck accelerating',
        type: 'tag',
        slug: '/sfx/tag/truck-accelerating',
      },
      { name: 'truck horn', type: 'tag', slug: '/sfx/tag/truck-horn' },
      {
        name: 'truck on gravel',
        type: 'tag',
        slug: '/sfx/tag/truck-on-gravel',
      },
      { name: 'vroom', type: 'tag', slug: '/sfx/tag/vroom' },
    ],
  },
  {
    id: 8,
    name: 'Scary',
    emoji: '😱',
    order: 8,
    tag: [
      { name: 'creature roar', type: 'tag', slug: '/sfx/tag/creature-roar' },
      { name: 'creepy', type: 'tag', slug: '/sfx/tag/creepy' },
      { name: 'creepy child', type: 'tag', slug: '/sfx/tag/creepy-child' },
      {
        name: 'creepy girl singing',
        type: 'tag',
        slug: '/sfx/tag/creepy-girl-singing',
      },
      { name: 'evil', type: 'style', slug: '/browse/sfx/evil' },
      { name: 'evil laugh', type: 'tag', slug: '/sfx/tag/evil-laugh' },
      { name: 'ghost', type: 'tag', slug: '/sfx/tag/ghost' },
      { name: 'goblin', type: 'tag', slug: '/sfx/tag/goblin' },
      { name: 'horror', type: 'style', slug: '/browse/sfx/horror' },
      { name: 'horror movie', type: 'tag', slug: '/sfx/tag/horror-movie' },
      { name: 'insane scream', type: 'tag', slug: '/sfx/tag/insane-scream' },
      { name: 'jumpscare', type: 'tag', slug: '/sfx/tag/jumpscare' },
      { name: 'monster', type: 'tag', slug: '/sfx/tag/monster' },
      { name: 'monster roar', type: 'tag', slug: '/sfx/tag/monster-roar' },
      { name: 'psycho', type: 'tag', slug: '/sfx/tag/psycho' },
      { name: 'scary', type: 'tag', slug: '/sfx/tag/scary' },
      { name: 'scary laugh', type: 'tag', slug: '/sfx/tag/scary-laugh' },
      { name: 'scary scream', type: 'tag', slug: '/sfx/tag/scary-scream' },
      {
        name: 'scary sounds horror',
        type: 'tag',
        slug: '/sfx/tag/scary-sounds-horror',
      },
      { name: 'scream', type: 'tag', slug: '/sfx/tag/scream' },
      { name: 'spooky', type: 'tag', slug: '/sfx/tag/spooky' },
      { name: 'suspense', type: 'style', slug: '/browse/sfx/suspense' },
      { name: 'zombie', type: 'tag', slug: '/sfx/tag/zombie' },
    ],
  },
  {
    id: 9,
    name: 'Weather',
    emoji: '🌧️',
    order: 9,
    tag: [
      { name: 'lightning', type: 'tag', slug: '/sfx/tag/lightning' },
      {
        name: 'lightning strike',
        type: 'tag',
        slug: '/sfx/tag/lightning-strike',
      },
      { name: 'rain', type: 'style', slug: '/browse/sfx/rain' },
      { name: 'snow', type: 'tag', slug: '/sfx/tag/snow' },
      { name: 'storm', type: 'style', slug: '/browse/sfx/storm' },
      { name: 'thunder', type: 'tag', slug: '/sfx/tag/thunder' },
      {
        name: 'thunder and lightning',
        type: 'tag',
        slug: '/sfx/tag/thunder-and-lightning',
      },
      { name: 'thunder clap', type: 'tag', slug: '/sfx/tag/thunder-clap' },
      {
        name: 'thunder no rain',
        type: 'tag',
        slug: '/sfx/tag/thunder-no-rain',
      },
      { name: 'thunderstorm', type: 'tag', slug: '/sfx/tag/thunderstorm' },
      { name: 'tornado', type: 'tag', slug: '/sfx/tag/tornado' },
      { name: 'wind', type: 'style', slug: '/browse/sfx/wind' },
      { name: 'wind blowing', type: 'tag', slug: '/sfx/tag/wind-blowing' },
    ],
  },
  {
    id: 10,
    name: 'Clicks & Typing',
    emoji: '💻',
    order: 10,
    tag: [
      { name: 'button click', type: 'tag', slug: '/sfx/tag/button-click' },
      { name: 'click', type: 'tag', slug: '/sfx/tag/click' },
      { name: 'keyboard', type: 'tag', slug: '/sfx/tag/keyboard' },
      {
        name: 'keyboard typing',
        type: 'tag',
        slug: '/sfx/tag/keyboard-typing',
      },
      { name: 'mouse', type: 'tag', slug: '/sfx/tag/mouse' },
      { name: 'mouse click', type: 'tag', slug: '/sfx/tag/mouse-click' },
      { name: 'typewriter', type: 'tag', slug: '/sfx/tag/typewriter' },
      { name: 'typing', type: 'tag', slug: '/sfx/tag/typing' },
    ],
  },
  {
    id: 11,
    name: 'Fighting',
    emoji: '👊',
    order: 11,
    tag: [
      { name: 'bone cracking', type: 'tag', slug: '/sfx/tag/bone-cracking' },
      { name: 'epic punch', type: 'tag', slug: '/sfx/tag/epic-punch' },
      { name: 'fighting', type: 'tag', slug: '/sfx/tag/fighting' },
      { name: 'hit', type: 'tag', slug: '/sfx/tag/hit' },
      { name: 'kick', type: 'tag', slug: '/sfx/tag/kick' },
      { name: 'punch', type: 'tag', slug: '/sfx/tag/punch' },
      { name: 'punching metal', type: 'tag', slug: '/sfx/tag/punching-metal' },
      { name: 'slap', type: 'tag', slug: '/sfx/tag/slap' },
      { name: 'slash', type: 'tag', slug: '/sfx/tag/slash' },
      { name: 'smack', type: 'tag', slug: '/sfx/tag/smack' },
      {
        name: 'someone getting hit',
        type: 'tag',
        slug: '/sfx/tag/someone-getting-hit',
      },
      { name: 'stab', type: 'tag', slug: '/sfx/tag/stab' },
      { name: 'violence', type: 'style', slug: '/browse/sfx/violence' },
    ],
  },
  {
    id: 12,
    name: 'Weapon',
    emoji: '🔫',
    order: 12,
    tag: [
      { name: 'ak47', type: 'tag', slug: '/sfx/tag/ak47' },
      { name: 'bang', type: 'tag', slug: '/sfx/tag/bang' },
      { name: 'bomb', type: 'tag', slug: '/sfx/tag/bomb' },
      { name: 'cannon', type: 'tag', slug: '/sfx/tag/cannon' },
      {
        name: 'colt 45 gunshot',
        type: 'tag',
        slug: '/sfx/tag/colt-45-gunshot',
      },
      { name: 'crit hit', type: 'tag', slug: '/sfx/tag/crit-hit' },
      { name: 'explosions', type: 'style', slug: '/browse/sfx/explosions' },
      { name: 'flashbang', type: 'tag', slug: '/sfx/tag/flashbang' },
      { name: 'gun', type: 'tag', slug: '/sfx/tag/gun' },
      { name: 'gun cock', type: 'tag', slug: '/sfx/tag/gun-cock' },
      { name: 'gunfire', type: 'tag', slug: '/sfx/tag/gunfire' },
      { name: 'gunshot', type: 'tag', slug: '/sfx/tag/gunshot' },
      { name: 'gunshots', type: 'tag', slug: '/sfx/tag/gunshots' },
      { name: 'handgun', type: 'tag', slug: '/sfx/tag/handgun' },
      { name: 'headshot', type: 'tag', slug: '/sfx/tag/headshot' },
      { name: 'knife', type: 'tag', slug: '/sfx/tag/knife' },
      { name: 'laser', type: 'tag', slug: '/sfx/tag/laser' },
      { name: 'loud gunshot', type: 'tag', slug: '/sfx/tag/loud-gunshot' },
      { name: 'm1 garand ping', type: 'tag', slug: '/sfx/tag/m1-garand-ping' },
      { name: 'm4 gun', type: 'tag', slug: '/sfx/tag/m4-gun' },
      { name: 'machine gun', type: 'tag', slug: '/sfx/tag/machine-gun' },
      { name: 'nuke', type: 'tag', slug: '/sfx/tag/nuke' },
      { name: 'pew', type: 'tag', slug: '/sfx/tag/pew' },
      { name: 'pistol', type: 'tag', slug: '/sfx/tag/pistol' },
      { name: 'pow', type: 'tag', slug: '/sfx/tag/pow' },
      { name: 'shot', type: 'tag', slug: '/sfx/tag/shot' },
      { name: 'shotgun', type: 'tag', slug: '/sfx/tag/shotgun' },
      { name: 'sword', type: 'tag', slug: '/sfx/tag/sword' },
      { name: 'sword slash', type: 'tag', slug: '/sfx/tag/sword-slash' },
      { name: 'war', type: 'tag', slug: '/sfx/tag/war' },
      { name: 'warzone', type: 'tag', slug: '/sfx/tag/warzone' },
      { name: 'weapon', type: 'tag', slug: '/sfx/tag/weapon' },
    ],
  },
  {
    id: 13,
    name: 'Sci-Fi & Space',
    emoji: '👽',
    order: 13,
    tag: [
      { name: 'alien', type: 'tag', slug: '/sfx/tag/alien' },
      {
        name: 'asteroid explosion',
        type: 'tag',
        slug: '/sfx/tag/asteroid-explosion',
      },
      { name: 'futuristic', type: 'tag', slug: '/sfx/tag/futuristic' },
      { name: 'laser gun', type: 'tag', slug: '/sfx/tag/laser-gun' },
      { name: 'lightsaber', type: 'tag', slug: '/sfx/tag/lightsaber' },
      { name: 'robot', type: 'tag', slug: '/sfx/tag/robot' },
      {
        name: 'robotic female warning',
        type: 'tag',
        slug: '/sfx/tag/robotic-female-warning',
      },
      { name: 'rocket', type: 'tag', slug: '/sfx/tag/rocket' },
      { name: 'rocket launch', type: 'tag', slug: '/sfx/tag/rocket-launch' },
      { name: 'sci-fi', type: 'style', slug: '/browse/sfx/sci-fi' },
      { name: 'sci-fi warning', type: 'tag', slug: '/sfx/tag/sci-fi-warning' },
      { name: 'sci-fi robot', type: 'tag', slug: '/sfx/tag/sci-fi-robot' },
      { name: 'space', type: 'tag', slug: '/sfx/tag/space' },
      { name: 'teleport', type: 'tag', slug: '/sfx/tag/teleport' },
      { name: 'ufo', type: 'tag', slug: '/sfx/tag/ufo' },
    ],
  },
  {
    id: 14,
    name: 'Domestic',
    emoji: '🏠',
    order: 14,
    tag: [
      { name: 'bathroom', type: 'tag', slug: '/sfx/tag/bathroom' },
      { name: 'camera', type: 'tag', slug: '/sfx/tag/camera' },
      { name: 'camera flash', type: 'tag', slug: '/sfx/tag/camera-flash' },
      { name: 'chainsaw', type: 'tag', slug: '/sfx/tag/chainsaw' },
      { name: 'clipper', type: 'tag', slug: '/sfx/tag/clipper' },
      { name: 'coin', type: 'tag', slug: '/sfx/tag/coin' },
      { name: 'cooking', type: 'tag', slug: '/sfx/tag/cooking' },
      { name: 'domestic', type: 'style', slug: '/browse/sfx/domestic' },
      { name: 'door closing', type: 'tag', slug: '/sfx/tag/door-closing' },
      { name: 'door knock', type: 'tag', slug: '/sfx/tag/door-knock' },
      { name: 'door opening', type: 'tag', slug: '/sfx/tag/door-opening' },
      { name: 'door slam', type: 'tag', slug: '/sfx/tag/door-slam' },
      { name: 'drill', type: 'tag', slug: '/sfx/tag/drill' },
      { name: 'fireplace', type: 'tag', slug: '/sfx/tag/fireplace' },
      {
        name: 'flag flapping in the wind',
        type: 'tag',
        slug: '/sfx/tag/flag-flapping-in-the-wind',
      },
      { name: 'garden', type: 'tag', slug: '/sfx/tag/garden' },
      { name: 'glass breaking', type: 'tag', slug: '/sfx/tag/glass-breaking' },
      { name: 'glass shards', type: 'tag', slug: '/sfx/tag/glass-shards' },
      {
        name: 'glass shattering',
        type: 'tag',
        slug: '/sfx/tag/glass-shattering',
      },
      { name: 'hammer', type: 'tag', slug: '/sfx/tag/hammer' },
      { name: 'hammer strike', type: 'tag', slug: '/sfx/tag/hammer-strike' },
      { name: 'keys', type: 'tag', slug: '/sfx/tag/keys' },
      { name: 'kitchen', type: 'tag', slug: '/sfx/tag/kitchen' },
      { name: 'knocking', type: 'tag', slug: '/sfx/tag/knocking' },
      { name: 'lawn mower', type: 'tag', slug: '/sfx/tag/lawn-mower' },
      {
        name: 'leather stretching',
        type: 'tag',
        slug: '/sfx/tag/leather-stretching',
      },
      { name: 'light switch', type: 'tag', slug: '/sfx/tag/light-switch' },
      {
        name: 'lighting a cigarette',
        type: 'tag',
        slug: '/sfx/tag/lighting-a-cigarette',
      },
      { name: 'lock', type: 'tag', slug: '/sfx/tag/lock' },
      { name: 'microwave', type: 'tag', slug: '/sfx/tag/microwave' },
      { name: 'nut', type: 'tag', slug: '/sfx/tag/nut' },
      {
        name: 'old photo camera',
        type: 'tag',
        slug: '/sfx/tag/old-photo-camera',
      },
      { name: 'oven', type: 'tag', slug: '/sfx/tag/oven' },
      { name: 'page turn', type: 'tag', slug: '/sfx/tag/page-turn' },
      { name: 'paper', type: 'tag', slug: '/sfx/tag/paper' },
      { name: 'radio tuning', type: 'tag', slug: '/sfx/tag/radio-tuning' },
      { name: 'ratchet', type: 'tag', slug: '/sfx/tag/ratchet' },
      { name: 'razor', type: 'tag', slug: '/sfx/tag/razor' },
      { name: 'records', type: 'tag', slug: '/sfx/tag/records' },
      { name: 'running water', type: 'tag', slug: '/sfx/tag/running-water' },
      { name: 'shower', type: 'tag', slug: '/sfx/tag/shower' },
      { name: 'smoke alarm', type: 'tag', slug: '/sfx/tag/smoke-alarm' },
      { name: 'spray', type: 'tag', slug: '/sfx/tag/spray' },
      { name: 'toilet flush', type: 'tag', slug: '/sfx/tag/toilet-flush' },
      { name: 'tools', type: 'tag', slug: '/sfx/tag/tools' },
      { name: 'vacuum', type: 'tag', slug: '/sfx/tag/vacuum' },
      { name: 'vacuum cleaner', type: 'tag', slug: '/sfx/tag/vacuum-cleaner' },
      { name: 'weed eater', type: 'tag', slug: '/sfx/tag/weed-eater' },
      { name: 'zipper', type: 'tag', slug: '/sfx/tag/zipper' },
    ],
  },
  {
    id: 15,
    name: 'Emotions',
    emoji: '😄',
    order: 15,
    tag: [
      { name: 'angry', type: 'tag', slug: '/sfx/tag/angry' },
      { name: 'anxious', type: 'style', slug: '/browse/sfx/anxious' },
      { name: 'awkward', type: 'style', slug: '/browse/sfx/awkward' },
      { name: 'badass', type: 'style', slug: '/browse/sfx/badass' },
      { name: 'calm', type: 'tag', slug: '/sfx/tag/calm' },
      { name: 'confused', type: 'tag', slug: '/sfx/tag/confused' },
      { name: 'cool', type: 'tag', slug: '/sfx/tag/cool' },
      { name: 'fun', type: 'tag', slug: '/sfx/tag/fun' },
      { name: 'happy', type: 'tag', slug: '/sfx/tag/happy' },
      { name: 'intense', type: 'tag', slug: '/sfx/tag/intense' },
      { name: 'love', type: 'style', slug: '/browse/sfx/love' },
      { name: 'rage', type: 'style', slug: '/browse/sfx/rage' },
      { name: 'relaxing', type: 'tag', slug: '/sfx/tag/relaxing' },
      { name: 'sad', type: 'tag', slug: '/sfx/tag/sad' },
      { name: 'shocked', type: 'tag', slug: '/sfx/tag/shocked' },
      { name: 'spiritual', type: 'tag', slug: '/sfx/tag/spiritual' },
      { name: 'surprise', type: 'tag', slug: '/sfx/tag/surprise' },
      { name: 'thinking', type: 'style', slug: '/browse/sfx/thinking' },
    ],
  },
  {
    id: 16,
    name: 'Supernatural',
    emoji: '👻',
    order: 16,
    tag: [
      { name: 'angel', type: 'tag', slug: '/sfx/tag/angel' },
      { name: 'dragon', type: 'tag', slug: '/sfx/tag/dragon' },
      { name: 'dream', type: 'tag', slug: '/sfx/tag/dream' },
      { name: 'flashback', type: 'tag', slug: '/sfx/tag/flashback' },
      { name: 'magic', type: 'tag', slug: '/sfx/tag/magic' },
      { name: 'supernatural', type: 'tag', slug: '/sfx/tag/supernatural' },
      { name: 'unexplained', type: 'style', slug: '/browse/sfx/unexplained' },
    ],
  },
  {
    id: 17,
    name: 'Pops & Dings',
    emoji: '🎈',
    order: 17,
    tag: [
      { name: 'bell ding', type: 'tag', slug: '/sfx/tag/bell-ding' },
      { name: 'bing bong', type: 'tag', slug: '/sfx/tag/bing-bong' },
      { name: 'bubble', type: 'tag', slug: '/sfx/tag/bubble' },
      { name: 'bubble pop', type: 'tag', slug: '/sfx/tag/bubble-pop' },
      { name: 'cash register', type: 'tag', slug: '/sfx/tag/cash-register' },
      { name: 'cha ching', type: 'tag', slug: '/sfx/tag/cha-ching' },
      { name: 'chink', type: 'tag', slug: '/sfx/tag/chink' },
      { name: 'ding', type: 'tag', slug: '/sfx/tag/ding' },
      { name: 'ding dong', type: 'tag', slug: '/sfx/tag/ding-dong' },
      { name: 'ping', type: 'tag', slug: '/sfx/tag/ping' },
      { name: 'pop', type: 'tag', slug: '/sfx/tag/pop' },
      { name: 'pop up', type: 'tag', slug: '/sfx/tag/pop-up' },
      { name: 'popping', type: 'tag', slug: '/sfx/tag/popping' },
    ],
  },
  {
    id: 18,
    name: 'Video Editing',
    emoji: '📹',
    order: 18,
    tag: [
      { name: 'animation', type: 'tag', slug: '/sfx/tag/animation' },
      { name: 'cinematic', type: 'tag', slug: '/sfx/tag/cinematic' },
      {
        name: 'cinematic opening',
        type: 'tag',
        slug: '/sfx/tag/cinematic-opening',
      },
      { name: 'cut', type: 'tag', slug: '/sfx/tag/cut' },
      { name: 'cutscene', type: 'tag', slug: '/sfx/tag/cutscene' },
      { name: 'dramatic', type: 'tag', slug: '/sfx/tag/dramatic' },
      { name: 'drones', type: 'style', slug: '/browse/sfx/drones' },
      { name: 'explosions', type: 'style', slug: '/browse/sfx/explosions' },
      { name: 'fast forward', type: 'tag', slug: '/sfx/tag/fast-forward' },
      { name: 'fast reverse', type: 'tag', slug: '/sfx/tag/fast-reverse' },
      { name: 'film', type: 'tag', slug: '/sfx/tag/film' },
      { name: 'foley', type: 'tag', slug: '/sfx/tag/foley' },
      {
        name: 'logos and idents',
        type: 'style',
        slug: '/browse/sfx/logos-and-idents',
      },
      { name: 'reverse', type: 'tag', slug: '/sfx/tag/reverse' },
      { name: 'rewind', type: 'tag', slug: '/sfx/tag/rewind' },
      { name: 'riser', type: 'tag', slug: '/sfx/tag/riser' },
      { name: 'slow motion', type: 'tag', slug: '/sfx/tag/slow-motion' },
      { name: 'swell', type: 'tag', slug: '/sfx/tag/swell' },
      { name: 'swipe', type: 'tag', slug: '/sfx/tag/swipe' },
      { name: 'swish', type: 'tag', slug: '/sfx/tag/swish' },
      { name: 'swoosh', type: 'tag', slug: '/sfx/tag/swoosh' },
      { name: 'transition', type: 'tag', slug: '/sfx/tag/transition' },
      { name: 'trailer', type: 'tag', slug: '/sfx/tag/trailer' },
      { name: 'whoosh', type: 'style', slug: '/browse/sfx/whoosh' },
      { name: 'woosh', type: 'tag', slug: '/sfx/tag/woosh' },
      { name: 'zoom', type: 'tag', slug: '/sfx/tag/zoom' },
      { name: 'zoom in', type: 'tag', slug: '/sfx/tag/zoom-in' },
    ],
  },
  {
    id: 19,
    name: 'Noises',
    emoji: '🔊',
    order: 19,
    tag: [
      { name: 'ambient', type: 'tag', slug: '/sfx/tag/ambient' },
      { name: 'atmosphere', type: 'tag', slug: '/sfx/tag/atmosphere' },
      { name: 'bounce', type: 'tag', slug: '/sfx/tag/bounce' },
      { name: 'breaking', type: 'tag', slug: '/sfx/tag/breaking' },
      { name: 'chatter', type: 'tag', slug: '/sfx/tag/chatter' },
      { name: 'crack', type: 'tag', slug: '/sfx/tag/crack' },
      { name: 'creaking', type: 'tag', slug: '/sfx/tag/creaking' },
      { name: 'crunch', type: 'tag', slug: '/sfx/tag/crunch' },
      { name: 'destroyed', type: 'tag', slug: '/sfx/tag/destroyed' },
      { name: 'echo', type: 'tag', slug: '/sfx/tag/echo' },
      { name: 'fizz', type: 'tag', slug: '/sfx/tag/fizz' },
      { name: 'glitch', type: 'tag', slug: '/sfx/tag/glitch' },
      { name: 'glitter', type: 'tag', slug: '/sfx/tag/glitter' },
      {
        name: 'high pitched squeal',
        type: 'tag',
        slug: '/sfx/tag/high-pitched-squeal',
      },
      { name: 'honk', type: 'tag', slug: '/sfx/tag/honk' },
      { name: 'howling', type: 'tag', slug: '/sfx/tag/howling' },
      { name: 'humming', type: 'tag', slug: '/sfx/tag/humming' },
      { name: 'loud', type: 'tag', slug: '/sfx/tag/loud' },
      { name: 'plop', type: 'tag', slug: '/sfx/tag/plop' },
      { name: 'poof', type: 'tag', slug: '/sfx/tag/poof' },
      { name: 'reverb', type: 'tag', slug: '/sfx/tag/reverb' },
      { name: 'scratch', type: 'tag', slug: '/sfx/tag/scratch' },
      { name: 'screech', type: 'tag', slug: '/sfx/tag/screech' },
      { name: 'sizzle', type: 'tag', slug: '/sfx/tag/sizzle' },
      { name: 'sparkle', type: 'tag', slug: '/sfx/tag/sparkle' },
      { name: 'spinning', type: 'tag', slug: '/sfx/tag/spinning' },
      { name: 'splat', type: 'tag', slug: '/sfx/tag/splat' },
      { name: 'squeak', type: 'tag', slug: '/sfx/tag/squeak' },
      { name: 'squeeze', type: 'tag', slug: '/sfx/tag/squeeze' },
      { name: 'squish', type: 'tag', slug: '/sfx/tag/squish' },
      { name: 'static', type: 'tag', slug: '/sfx/tag/static' },
      { name: 'sweep', type: 'tag', slug: '/sfx/tag/sweep' },
      { name: 'swinging', type: 'tag', slug: '/sfx/tag/swinging' },
      { name: 'thwip', type: 'tag', slug: '/sfx/tag/thwip' },
      { name: 'twinkle', type: 'tag', slug: '/sfx/tag/twinkle' },
      { name: 'vinyl 1080p', type: 'tag', slug: '/sfx/tag/vinyl-1080p' },
      { name: 'waterphone', type: 'tag', slug: '/sfx/tag/waterphone' },
      { name: 'white noise', type: 'tag', slug: '/sfx/tag/white-noise' },
    ],
  },
  {
    id: 20,
    name: 'Crowd',
    emoji: '👥',
    order: 20,
    tag: [
      { name: 'applause', type: 'tag', slug: '/sfx/tag/applause' },
      { name: 'audience', type: 'tag', slug: '/sfx/tag/audience' },
      { name: 'booing', type: 'tag', slug: '/sfx/tag/booing' },
      { name: 'cheer', type: 'tag', slug: '/sfx/tag/cheer' },
      { name: 'cheering', type: 'tag', slug: '/sfx/tag/cheering' },
      { name: 'clapping', type: 'tag', slug: '/sfx/tag/clapping' },
      { name: 'crowd', type: 'tag', slug: '/sfx/tag/crowd' },
      { name: 'crowd booing', type: 'tag', slug: '/sfx/tag/crowd-booing' },
      { name: 'crowd cheering', type: 'tag', slug: '/sfx/tag/crowd-cheering' },
      { name: 'crowd gasp', type: 'tag', slug: '/sfx/tag/crowd-gasp' },
      {
        name: 'crowd screaming',
        type: 'tag',
        slug: '/sfx/tag/crowd-screaming',
      },
      { name: 'demonstration', type: 'tag', slug: '/sfx/tag/demonstration' },
      { name: 'laugh track', type: 'tag', slug: '/sfx/tag/laugh-track' },
      {
        name: 'people screaming',
        type: 'tag',
        slug: '/sfx/tag/people-screaming',
      },
      { name: 'people talking', type: 'tag', slug: '/sfx/tag/people-talking' },
    ],
  },
  {
    id: 21,
    name: 'Occasions',
    emoji: '🎉',
    order: 21,
    tag: [
      { name: 'breaking news', type: 'tag', slug: '/sfx/tag/breaking-news' },
      { name: 'celebrate', type: 'style', slug: '/browse/sfx/celebrate' },
      { name: 'cheers', type: 'tag', slug: '/sfx/tag/cheers' },
      { name: 'christmas', type: 'tag', slug: '/sfx/tag/christmas' },
      { name: 'confetti', type: 'tag', slug: '/sfx/tag/confetti' },
      { name: 'firework', type: 'tag', slug: '/sfx/tag/firework' },
      { name: 'fireworks', type: 'tag', slug: '/sfx/tag/fireworks' },
      { name: 'halloween', type: 'tag', slug: '/sfx/tag/halloween' },
      { name: 'meditation', type: 'tag', slug: '/sfx/tag/meditation' },
      { name: 'party', type: 'tag', slug: '/sfx/tag/party' },
      { name: 'party horn', type: 'tag', slug: '/sfx/tag/party-horn' },
    ],
  },
  {
    id: 22,
    name: 'Places',
    emoji: '🏞️',
    order: 22,
    tag: [
      { name: 'beach', type: 'tag', slug: '/sfx/tag/beach' },
      { name: 'city', type: 'style', slug: '/browse/sfx/city' },
      { name: 'farm', type: 'tag', slug: '/sfx/tag/farm' },
      { name: 'forest', type: 'style', slug: '/browse/sfx/forest' },
      { name: 'hospital', type: 'tag', slug: '/sfx/tag/hospital' },
      { name: 'industrial', type: 'style', slug: '/browse/sfx/industrial' },
      { name: 'jungle', type: 'tag', slug: '/sfx/tag/jungle' },
      { name: 'room tone', type: 'tag', slug: '/sfx/tag/room-tone' },
      { name: 'ocean', type: 'style', slug: '/browse/sfx/ocean' },
      { name: 'rainforest', type: 'tag', slug: '/sfx/tag/rainforest' },
      { name: 'rural', type: 'style', slug: '/browse/sfx/rural' },
      { name: 'underground', type: 'tag', slug: '/sfx/tag/underground' },
      { name: 'waterfall', type: 'tag', slug: '/sfx/tag/waterfall' },
      { name: 'woodland', type: 'style', slug: '/browse/sfx/woodland' },
    ],
  },
  {
    id: 23,
    name: 'Body',
    emoji: '💪',
    order: 23,
    tag: [
      { name: 'big bite', type: 'tag', slug: '/sfx/tag/big-bite' },
      { name: 'bite', type: 'tag', slug: '/sfx/tag/bite' },
      { name: 'blink', type: 'tag', slug: '/sfx/tag/blink' },
      { name: 'body', type: 'style', slug: '/browse/sfx/body' },
      { name: 'brap', type: 'tag', slug: '/sfx/tag/brap' },
      { name: 'breathing', type: 'tag', slug: '/sfx/tag/breathing' },
      { name: 'burp', type: 'tag', slug: '/sfx/tag/burp' },
      { name: 'bwomp', type: 'tag', slug: '/sfx/tag/bwomp' },
      { name: 'chomp', type: 'tag', slug: '/sfx/tag/chomp' },
      { name: 'clap', type: 'tag', slug: '/sfx/tag/clap' },
      { name: 'cough', type: 'tag', slug: '/sfx/tag/cough' },
      { name: 'coughing', type: 'tag', slug: '/sfx/tag/coughing' },
      { name: 'diarrhea', type: 'tag', slug: '/sfx/tag/diarrhea' },
      { name: 'drawing', type: 'tag', slug: '/sfx/tag/drawing' },
      { name: 'drinking', type: 'tag', slug: '/sfx/tag/drinking' },
      { name: 'ear ringing', type: 'tag', slug: '/sfx/tag/ear-ringing' },
      { name: 'eating', type: 'tag', slug: '/sfx/tag/eating' },
      { name: 'falling', type: 'tag', slug: '/sfx/tag/falling' },
      { name: 'fard', type: 'tag', slug: '/sfx/tag/fard' },
      { name: 'fart', type: 'tag', slug: '/sfx/tag/fart' },
      { name: 'fast clapping', type: 'tag', slug: '/sfx/tag/fast-clapping' },
      { name: 'flicking', type: 'tag', slug: '/sfx/tag/flicking' },
      { name: 'footstep', type: 'tag', slug: '/sfx/tag/footstep' },
      { name: 'footsteps', type: 'tag', slug: '/sfx/tag/footsteps' },
      { name: 'gasp', type: 'tag', slug: '/sfx/tag/gasp' },
      { name: 'grunt', type: 'tag', slug: '/sfx/tag/grunt' },
      { name: 'gulp', type: 'tag', slug: '/sfx/tag/gulp' },
      { name: 'heartbeat', type: 'tag', slug: '/sfx/tag/heartbeat' },
      {
        name: 'heavy breathing',
        type: 'tag',
        slug: '/sfx/tag/heavy-breathing',
      },
      { name: 'heavy stomp', type: 'tag', slug: '/sfx/tag/heavy-stomp' },
      { name: 'huge fart', type: 'tag', slug: '/sfx/tag/huge-fart' },
      { name: 'jump', type: 'tag', slug: '/sfx/tag/jump' },
      { name: 'kiss', type: 'tag', slug: '/sfx/tag/kiss' },
      { name: 'longest fart', type: 'tag', slug: '/sfx/tag/longest-fart' },
      { name: 'loud fart', type: 'tag', slug: '/sfx/tag/loud-fart' },
      { name: 'mouth', type: 'tag', slug: '/sfx/tag/mouth' },
      { name: 'pee', type: 'tag', slug: '/sfx/tag/pee' },
      { name: 'ripping', type: 'tag', slug: '/sfx/tag/ripping' },
      { name: 'run', type: 'tag', slug: '/sfx/tag/run' },
      { name: 'running', type: 'tag', slug: '/sfx/tag/running' },
      {
        name: 'running cartoon',
        type: 'tag',
        slug: '/sfx/tag/running-cartoon',
      },
      { name: 'shart', type: 'tag', slug: '/sfx/tag/shart' },
      { name: 'slip', type: 'tag', slug: '/sfx/tag/slip' },
      { name: 'slurp', type: 'tag', slug: '/sfx/tag/slurp' },
      { name: 'snore', type: 'tag', slug: '/sfx/tag/snore' },
      { name: 'stamp', type: 'tag', slug: '/sfx/tag/stamp' },
      {
        name: 'stomach growling',
        type: 'tag',
        slug: '/sfx/tag/stomach-growling',
      },
      { name: 'stomp', type: 'tag', slug: '/sfx/tag/stomp' },
      { name: 'tapping', type: 'tag', slug: '/sfx/tag/tapping' },
      { name: 'throwing', type: 'tag', slug: '/sfx/tag/throwing' },
      { name: 'vomit', type: 'tag', slug: '/sfx/tag/vomit' },
      { name: 'walking', type: 'tag', slug: '/sfx/tag/walking' },
      { name: 'wet fart', type: 'tag', slug: '/sfx/tag/wet-fart' },
      { name: 'wink', type: 'tag', slug: '/sfx/tag/wink' },
      { name: 'writing', type: 'tag', slug: '/sfx/tag/writing' },
    ],
  },
  {
    id: 24,
    name: 'Gaming & Sports',
    emoji: '🎮',
    order: 24,
    tag: [
      { name: '8 bit', type: 'tag', slug: '/sfx/tag/8-bit' },
      { name: 'baseball', type: 'tag', slug: '/sfx/tag/baseball' },
      { name: 'basketball', type: 'tag', slug: '/sfx/tag/basketball' },
      { name: 'basketball net', type: 'tag', slug: '/sfx/tag/basketball-net' },
      {
        name: 'boxing speed bag',
        type: 'tag',
        slug: '/sfx/tag/boxing-speed-bag',
      },
      { name: 'casino winner', type: 'tag', slug: '/sfx/tag/casino-winner' },
      { name: 'football', type: 'tag', slug: '/sfx/tag/football' },
      { name: 'game', type: 'tag', slug: '/sfx/tag/game' },
      { name: 'game over', type: 'tag', slug: '/sfx/tag/game-over' },
      { name: 'gaming', type: 'style', slug: '/browse/sfx/gaming' },
      { name: 'pc game', type: 'tag', slug: '/sfx/tag/pc-game' },
      { name: 'power up', type: 'tag', slug: '/sfx/tag/power-up' },
      { name: 'quiz', type: 'tag', slug: '/sfx/tag/quiz' },
      { name: 'sports', type: 'style', slug: '/browse/sfx/sports' },
      { name: 'toy', type: 'tag', slug: '/sfx/tag/toy' },
      { name: 'video game', type: 'tag', slug: '/sfx/tag/video-game' },
      { name: 'wooden blocks', type: 'tag', slug: '/sfx/tag/wooden-blocks' },
    ],
  },
  {
    id: 25,
    name: 'Music Clips',
    emoji: '🎵',
    order: 25,
    tag: [
      { name: 'angel choir', type: 'tag', slug: '/sfx/tag/angel-choir' },
      { name: 'angelic harp', type: 'tag', slug: '/sfx/tag/angelic-harp' },
      { name: 'angels singing', type: 'tag', slug: '/sfx/tag/angels-singing' },
      { name: 'bass', type: 'tag', slug: '/sfx/tag/bass' },
      { name: 'bass boost', type: 'tag', slug: '/sfx/tag/bass-boost' },
      { name: 'bass drop', type: 'tag', slug: '/sfx/tag/bass-drop' },
      { name: 'beat drop', type: 'tag', slug: '/sfx/tag/beat-drop' },
      { name: 'choir', type: 'tag', slug: '/sfx/tag/choir' },
      { name: 'cymbal roll', type: 'tag', slug: '/sfx/tag/cymbal-roll' },
      { name: 'DJ', type: 'tag', slug: '/sfx/tag/DJ' },
      { name: 'DJ risers', type: 'tag', slug: '/sfx/tag/DJ-risers' },
      { name: 'DJ sampler', type: 'tag', slug: '/sfx/tag/DJ-sampler' },
      { name: 'dramatic music', type: 'tag', slug: '/sfx/tag/dramatic-music' },
      { name: 'drum', type: 'tag', slug: '/sfx/tag/drum' },
      { name: 'drum countdown', type: 'tag', slug: '/sfx/tag/drum-countdown' },
      { name: 'drum roll', type: 'tag', slug: '/sfx/tag/drum-roll' },
      { name: 'gong', type: 'tag', slug: '/sfx/tag/gong' },
      { name: 'guitar', type: 'tag', slug: '/sfx/tag/guitar' },
      { name: 'harp', type: 'tag', slug: '/sfx/tag/harp' },
      { name: 'horn', type: 'tag', slug: '/sfx/tag/horn' },
      { name: 'music', type: 'tag', slug: '/sfx/tag/music' },
      { name: 'orchestra', type: 'tag', slug: '/sfx/tag/orchestra' },
      { name: 'piano', type: 'tag', slug: '/sfx/tag/piano' },
      { name: 'rimshot', type: 'tag', slug: '/sfx/tag/rimshot' },
      { name: 'sad music', type: 'tag', slug: '/sfx/tag/sad-music' },
      { name: 'sad violin', type: 'tag', slug: '/sfx/tag/sad-violin' },
      { name: 'stinger', type: 'tag', slug: '/sfx/tag/stinger' },
      { name: 'strings', type: 'tag', slug: '/sfx/tag/strings' },
      { name: 'suspense music', type: 'tag', slug: '/sfx/tag/suspense-music' },
      { name: 'trombone', type: 'tag', slug: '/sfx/tag/trombone' },
      { name: 'trumpet', type: 'tag', slug: '/sfx/tag/trumpet' },
      {
        name: 'trumpet fanfare',
        type: 'tag',
        slug: '/sfx/tag/trumpet-fanfare',
      },
      { name: 'tuba', type: 'tag', slug: '/sfx/tag/tuba' },
    ],
  },
  {
    id: 26,
    name: 'Voice Clips',
    emoji: '🗣️',
    order: 26,
    tag: [
      { name: 'ahhhh', type: 'tag', slug: '/sfx/tag/ahhhh' },
      { name: 'aww', type: 'tag', slug: '/sfx/tag/aww' },
      { name: 'baby', type: 'tag', slug: '/sfx/tag/baby' },
      { name: 'baby crying', type: 'tag', slug: '/sfx/tag/baby-crying' },
      { name: 'boo', type: 'tag', slug: '/sfx/tag/boo' },
      { name: 'bye', type: 'tag', slug: '/sfx/tag/bye' },
      { name: 'children', type: 'tag', slug: '/sfx/tag/children' },
      { name: 'crying', type: 'tag', slug: '/sfx/tag/crying' },
      { name: 'famous scream', type: 'tag', slug: '/sfx/tag/famous-scream' },
      {
        name: 'female giggling',
        type: 'tag',
        slug: '/sfx/tag/female-giggling',
      },
      { name: 'girl moan', type: 'tag', slug: '/sfx/tag/girl-moan' },
      {
        name: 'girl saying yes',
        type: 'tag',
        slug: '/sfx/tag/girl-saying-yes',
      },
      { name: 'girl screaming', type: 'tag', slug: '/sfx/tag/girl-screaming' },
      { name: 'growl', type: 'tag', slug: '/sfx/tag/growl' },
      { name: 'hello', type: 'tag', slug: '/sfx/tag/hello' },
      { name: 'help me', type: 'tag', slug: '/sfx/tag/help-me' },
      { name: 'hey', type: 'tag', slug: '/sfx/tag/hey' },
      { name: 'hi', type: 'tag', slug: '/sfx/tag/hi' },
      { name: 'hooray', type: 'tag', slug: '/sfx/tag/hooray' },
      { name: 'man crying', type: 'tag', slug: '/sfx/tag/man-crying' },
      { name: 'man screaming', type: 'tag', slug: '/sfx/tag/man-screaming' },
      {
        name: 'man yelling timber',
        type: 'tag',
        slug: '/sfx/tag/man-yelling-timber',
      },
      { name: 'moan', type: 'tag', slug: '/sfx/tag/moan' },
      { name: 'moaning', type: 'tag', slug: '/sfx/tag/moaning' },
      { name: 'nice', type: 'tag', slug: '/sfx/tag/nice' },
      { name: 'nope', type: 'tag', slug: '/sfx/tag/nope' },
      { name: 'oh no', type: 'tag', slug: '/sfx/tag/oh-no' },
      { name: 'oh yeah', type: 'tag', slug: '/sfx/tag/oh-yeah' },
      { name: 'oh yeah female', type: 'tag', slug: '/sfx/tag/oh-yeah-female' },
      { name: 'omg', type: 'tag', slug: '/sfx/tag/omg' },
      { name: 'ooh', type: 'tag', slug: '/sfx/tag/ooh' },
      { name: 'ooh noo', type: 'tag', slug: '/sfx/tag/ooh-noo' },
      { name: 'perfect', type: 'tag', slug: '/sfx/tag/perfect' },
      { name: 'roar', type: 'tag', slug: '/sfx/tag/roar' },
      { name: 'satisfied ahh', type: 'tag', slug: '/sfx/tag/satisfied-ahh' },
      { name: 'saying goodbye', type: 'tag', slug: '/sfx/tag/saying-goodbye' },
      { name: 'sheesh', type: 'tag', slug: '/sfx/tag/sheesh' },
      { name: 'shhh', type: 'tag', slug: '/sfx/tag/shhh' },
      { name: 'shout', type: 'tag', slug: '/sfx/tag/shout' },
      { name: 'shut up', type: 'tag', slug: '/sfx/tag/shut-up' },
      { name: 'snoring', type: 'tag', slug: '/sfx/tag/snoring' },
      { name: 'uh oh', type: 'tag', slug: '/sfx/tag/uh-oh' },
      { name: 'voice', type: 'tag', slug: '/sfx/tag/voice' },
      { name: 'voices in head', type: 'tag', slug: '/sfx/tag/voices-in-head' },
      { name: 'war screaming', type: 'tag', slug: '/sfx/tag/war-screaming' },
      { name: 'wee', type: 'tag', slug: '/sfx/tag/wee' },
      { name: 'whisper', type: 'tag', slug: '/sfx/tag/whisper' },
      { name: 'woah', type: 'tag', slug: '/sfx/tag/woah' },
      { name: 'woman arguing', type: 'tag', slug: '/sfx/tag/woman-arguing' },
      { name: 'woman crying', type: 'tag', slug: '/sfx/tag/woman-crying' },
      {
        name: 'woman experiencing pleasure',
        type: 'tag',
        slug: '/sfx/tag/woman-experiencing-pleasure',
      },
      {
        name: 'woman saying warning',
        type: 'tag',
        slug: '/sfx/tag/woman-saying-warning',
      },
      {
        name: 'woman screaming',
        type: 'tag',
        slug: '/sfx/tag/woman-screaming',
      },
      { name: 'woman shh', type: 'tag', slug: '/sfx/tag/woman-shh' },
      { name: 'wow', type: 'tag', slug: '/sfx/tag/wow' },
      { name: 'yay', type: 'tag', slug: '/sfx/tag/yay' },
      { name: 'yeah', type: 'tag', slug: '/sfx/tag/yeah' },
      { name: 'yeet', type: 'tag', slug: '/sfx/tag/yeet' },
      { name: 'yelling', type: 'tag', slug: '/sfx/tag/yelling' },
      { name: 'yes', type: 'tag', slug: '/sfx/tag/yes' },
      { name: 'yes sir', type: 'tag', slug: '/sfx/tag/yes-sir' },
      {
        name: 'you are welcome',
        type: 'tag',
        slug: '/sfx/tag/you-are-welcome',
      },
    ],
  },
  {
    id: 27,
    name: 'Notification',
    emoji: '🔔',
    order: 27,
    tag: [
      { name: 'alert', type: 'tag', slug: '/sfx/tag/alert' },
      { name: 'call', type: 'tag', slug: '/sfx/tag/call' },
      { name: 'calling', type: 'tag', slug: '/sfx/tag/calling' },
      { name: 'notification', type: 'tag', slug: '/sfx/tag/notification' },
      {
        name: 'notification bell youtube',
        type: 'tag',
        slug: '/sfx/tag/notification-bell-youtube',
      },
      { name: 'notify', type: 'tag', slug: '/sfx/tag/notify' },
      { name: 'phone', type: 'tag', slug: '/sfx/tag/phone' },
      { name: 'phone call', type: 'tag', slug: '/sfx/tag/phone-call' },
      { name: 'phone ringing', type: 'tag', slug: '/sfx/tag/phone-ringing' },
    ],
  },
  {
    id: 28,
    name: 'Impacts',
    emoji: '💥',
    order: 28,
    tag: [
      { name: 'booms', type: 'style', slug: '/browse/sfx/booms' },
      { name: 'crash', type: 'tag', slug: '/sfx/tag/crash' },
      { name: 'dramatic boom', type: 'tag', slug: '/sfx/tag/dramatic-boom' },
      { name: 'impact', type: 'tag', slug: '/sfx/tag/impact' },
      { name: 'rumbles', type: 'style', slug: '/browse/sfx/rumbles' },
      { name: 'slam', type: 'tag', slug: '/sfx/tag/slam' },
      { name: 'snap', type: 'tag', slug: '/sfx/tag/snap' },
      { name: 'sonic boom', type: 'tag', slug: '/sfx/tag/sonic-boom' },
      { name: 'thud', type: 'tag', slug: '/sfx/tag/thud' },
      { name: 'thump', type: 'tag', slug: '/sfx/tag/thump' },
      { name: 'whip', type: 'tag', slug: '/sfx/tag/whip' },
    ],
  },
  {
    id: 29,
    name: 'Mechanical',
    emoji: '⚙️',
    order: 29,
    tag: [
      { name: 'alarm clock', type: 'tag', slug: '/sfx/tag/alarm-clock' },
      { name: 'camera shutter', type: 'tag', slug: '/sfx/tag/camera-shutter' },
      { name: 'clock', type: 'tag', slug: '/sfx/tag/clock' },
      { name: 'clock ticking', type: 'tag', slug: '/sfx/tag/clock-ticking' },
      { name: 'countdown', type: 'tag', slug: '/sfx/tag/countdown' },
      { name: 'fan', type: 'tag', slug: '/sfx/tag/fan' },
      { name: 'gears', type: 'tag', slug: '/sfx/tag/gears' },
      { name: 'machine', type: 'tag', slug: '/sfx/tag/machine' },
      { name: 'slot machine', type: 'tag', slug: '/sfx/tag/slot-machine' },
      { name: 'timer', type: 'tag', slug: '/sfx/tag/timer' },
    ],
  },
  {
    id: 30,
    name: 'Elements',
    emoji: '💧',
    order: 30,
    tag: [
      { name: 'air blow', type: 'tag', slug: '/sfx/tag/air-blow' },
      {
        name: 'dive into water',
        type: 'tag',
        slug: '/sfx/tag/dive-into-water',
      },
      { name: 'drip', type: 'tag', slug: '/sfx/tag/drip' },
      { name: 'earthquake', type: 'tag', slug: '/sfx/tag/earthquake' },
      { name: 'fire', type: 'tag', slug: '/sfx/tag/fire' },
      { name: 'fire crackling', type: 'tag', slug: '/sfx/tag/fire-crackling' },
      { name: 'fire thrower', type: 'tag', slug: '/sfx/tag/fire-thrower' },
      { name: 'gas', type: 'tag', slug: '/sfx/tag/gas' },
      { name: 'ice', type: 'tag', slug: '/sfx/tag/ice' },
      { name: 'liquid', type: 'tag', slug: '/sfx/tag/liquid' },
      { name: 'splash', type: 'tag', slug: '/sfx/tag/splash' },
      { name: 'swimming', type: 'tag', slug: '/sfx/tag/swimming' },
      { name: 'underwater', type: 'tag', slug: '/sfx/tag/underwater' },
      { name: 'water', type: 'tag', slug: '/sfx/tag/water' },
      { name: 'water drop', type: 'tag', slug: '/sfx/tag/water-drop' },
    ],
  },
  {
    id: 31,
    name: 'Digital',
    emoji: '📱',
    order: 31,
    tag: [
      { name: 'bass swell', type: 'tag', slug: '/sfx/tag/bass-swell' },
      { name: 'computer', type: 'tag', slug: '/sfx/tag/computer' },
      { name: 'data', type: 'tag', slug: '/sfx/tag/data' },
      { name: 'digital', type: 'tag', slug: '/sfx/tag/digital' },
      { name: 'disable trash', type: 'tag', slug: '/sfx/tag/disable-trash' },
      { name: 'err', type: 'tag', slug: '/sfx/tag/err' },
      { name: 'error', type: 'tag', slug: '/sfx/tag/error' },
      { name: 'loading', type: 'tag', slug: '/sfx/tag/loading' },
      { name: 'processing', type: 'tag', slug: '/sfx/tag/processing' },
      { name: 'ringtone', type: 'tag', slug: '/sfx/tag/ringtone' },
      { name: 'scanning', type: 'tag', slug: '/sfx/tag/scanning' },
      { name: 'synthetic', type: 'tag', slug: '/sfx/tag/synthetic' },
      { name: 'text', type: 'tag', slug: '/sfx/tag/text' },
      { name: 'virus', type: 'tag', slug: '/sfx/tag/virus' },
    ],
  },
  {
    id: 32,
    name: 'Technology & Science',
    emoji: '🧪',
    order: 32,
    tag: [
      { name: 'calculation', type: 'tag', slug: '/sfx/tag/calculation' },
      { name: 'dial up', type: 'tag', slug: '/sfx/tag/dial-up' },
      { name: 'ecg machine', type: 'tag', slug: '/sfx/tag/ecg-machine' },
      { name: 'electric', type: 'tag', slug: '/sfx/tag/electric' },
      {
        name: 'electric shutdown',
        type: 'tag',
        slug: '/sfx/tag/electric-shutdown',
      },
      { name: 'electrical pop', type: 'tag', slug: '/sfx/tag/electrical-pop' },
      { name: 'electricity', type: 'tag', slug: '/sfx/tag/electricity' },
      { name: 'eletric', type: 'tag', slug: '/sfx/tag/eletric' },
      { name: 'microphone', type: 'tag', slug: '/sfx/tag/microphone' },
      { name: 'modem', type: 'tag', slug: '/sfx/tag/modem' },
      { name: 'power outage', type: 'tag', slug: '/sfx/tag/power-outage' },
      { name: 'radio', type: 'tag', slug: '/sfx/tag/radio' },
      { name: 'radio cut out', type: 'tag', slug: '/sfx/tag/radio-cut-out' },
      { name: 'radio static', type: 'tag', slug: '/sfx/tag/radio-static' },
      { name: 'science', type: 'style', slug: '/browse/sfx/science' },
      { name: 'shock', type: 'tag', slug: '/sfx/tag/shock' },
      { name: 'tech', type: 'tag', slug: '/sfx/tag/tech' },
      { name: 'technical', type: 'style', slug: '/browse/sfx/technical' },
      { name: 'transmission', type: 'tag', slug: '/sfx/tag/transmission' },
      { name: 'tv static', type: 'tag', slug: '/sfx/tag/tv-static' },
      { name: 'vhs', type: 'tag', slug: '/sfx/tag/vhs' },
      { name: 'walkie talkie', type: 'tag', slug: '/sfx/tag/walkie-talkie' },
      { name: 'zap', type: 'tag', slug: '/sfx/tag/zap' },
    ],
  },
  {
    id: 33,
    name: 'Other',
    emoji: '🌀',
    order: 33,
    tag: [
      { name: '80s', type: 'tag', slug: '/sfx/tag/80s' },
      { name: 'answer', type: 'tag', slug: '/sfx/tag/answer' },
      { name: 'background', type: 'tag', slug: '/sfx/tag/background' },
      {
        name: 'building demolition',
        type: 'tag',
        slug: '/sfx/tag/building-demolition',
      },
      { name: 'charge', type: 'tag', slug: '/sfx/tag/charge' },
      { name: 'craft', type: 'style', slug: '/browse/sfx/craft' },
      { name: 'creature', type: 'tag', slug: '/sfx/tag/creature' },
      { name: 'cute', type: 'tag', slug: '/sfx/tag/cute' },
      { name: 'death', type: 'tag', slug: '/sfx/tag/death' },
      { name: 'dust particle', type: 'tag', slug: '/sfx/tag/dust-particle' },
      { name: 'game show', type: 'tag', slug: '/sfx/tag/game-show' },
      { name: 'gavel', type: 'tag', slug: '/sfx/tag/gavel' },
      { name: 'heaven', type: 'tag', slug: '/sfx/tag/heaven' },
      { name: 'huh', type: 'tag', slug: '/sfx/tag/huh' },
      { name: 'intro', type: 'tag', slug: '/sfx/tag/intro' },
      { name: 'medieval', type: 'tag', slug: '/sfx/tag/medieval' },
      { name: 'metallic', type: 'tag', slug: '/sfx/tag/metallic' },
      { name: 'money', type: 'tag', slug: '/sfx/tag/money' },
      { name: 'news', type: 'tag', slug: '/sfx/tag/news' },
      { name: 'no', type: 'tag', slug: '/sfx/tag/no' },
      { name: 'old', type: 'tag', slug: '/sfx/tag/old' },
      { name: 'opening', type: 'tag', slug: '/sfx/tag/opening' },
      { name: 'pause', type: 'tag', slug: '/sfx/tag/pause' },
      { name: 'police', type: 'tag', slug: '/sfx/tag/police' },
      { name: 'random', type: 'tag', slug: '/sfx/tag/random' },
      { name: 'retro', type: 'tag', slug: '/sfx/tag/retro' },
      { name: 'reveal', type: 'style', slug: '/browse/sfx/reveal' },
      { name: 'smash', type: 'tag', slug: '/sfx/tag/smash' },
      {
        name: 'South Indian movie',
        type: 'tag',
        slug: '/sfx/tag/South-Indian-movie',
      },
      { name: 'stop', type: 'tag', slug: '/sfx/tag/stop' },
      { name: 'study', type: 'tag', slug: '/sfx/tag/study' },
      { name: 'vintage', type: 'tag', slug: '/sfx/tag/vintage' },
      { name: 'wake up', type: 'tag', slug: '/sfx/tag/wake-up' },
      { name: 'weird', type: 'tag', slug: '/sfx/tag/weird' },
      { name: 'wellness', type: 'tag', slug: '/sfx/tag/wellness' },
      { name: 'western', type: 'tag', slug: '/sfx/tag/western' },
      { name: 'wood', type: 'tag', slug: '/sfx/tag/wood' },
    ],
  },
];

export const staticComprehensiveListingsMusic: CategoryWithType[] = [
  {
    id: 0,
    name: 'Moods',
    order: 0,
    emoji: '',
    tag: [
      { name: 'Aesthetic', type: 'tag', slug: '/music/tag/aesthetic' },
      { name: 'Calm', type: 'tag', slug: '/browse/music/calm' },
      { name: 'Chill', type: 'tag', slug: '/music/mood/chill' },
      { name: 'Creepy', type: 'tag', slug: '/music/mood/creepy' },
      { name: 'Dark', type: 'tag', slug: '/browse/music/dark' },
      { name: 'Dramatic', type: 'tag', slug: '/browse/music/dramatic' },
      { name: 'Emotional', type: 'tag', slug: '/music/mood/emotional' },
      { name: 'Energetic', type: 'tag', slug: '/music/mood/energetic' },
      { name: 'Fast', type: 'tag', slug: '/music/mood/fast' },
      { name: 'Funny', type: 'tag', slug: '/music/mood/funny' },
      { name: 'Happy', type: 'tag', slug: '/browse/music/happy' },
      { name: 'Inspirational', type: 'tag', slug: '/music/mood/inspirational' },
      { name: 'Intense', type: 'tag', slug: '/music/mood/intense' },
      { name: 'Love', type: 'tag', slug: '/music/mood/love' },
      { name: 'Motivational', type: 'tag', slug: '/music/mood/motivational' },
      { name: 'Patriotic', type: 'tag', slug: '/music/mood/patriotic' },
      { name: 'Relaxing', type: 'tag', slug: '/music/mood/relaxing' },
      { name: 'Romantic', type: 'tag', slug: '/music/mood/romantic' },
      { name: 'Sad', type: 'tag', slug: '/browse/music/sad' },
      { name: 'Scary', type: 'tag', slug: '/browse/music/scary' },
      { name: 'Slow', type: 'tag', slug: '/music/mood/slow' },
      { name: 'Sneaky', type: 'tag', slug: '/music/mood/sneaky' },
      { name: 'Soft', type: 'tag', slug: '/music/tag/soft' },
      { name: 'Stylish', type: 'tag', slug: '/music/tag/stylish' },
      { name: 'Suspense', type: 'tag', slug: '/music/mood/suspense' },
      { name: 'Upbeat', type: 'tag', slug: '/music/mood/upbeat' },
      { name: 'Uplifting', type: 'tag', slug: '/browse/music/uplifting' },
    ],
  },
  {
    id: 1,
    name: 'Instruments',
    order: 1,
    emoji: '',
    tag: [
      { name: 'Acoustic', type: 'tag', slug: '/browse/music/acoustic' },
      { name: 'Bass', type: 'tag', slug: '/music/tag/bass' },
      { name: 'Drum', type: 'tag', slug: '/music/instrument/drum' },
      { name: 'Flute', type: 'tag', slug: '/music/instrument/flute' },
      { name: 'Guitar', type: 'tag', slug: '/music/instrument/guitar' },
      { name: 'Piano', type: 'tag', slug: '/music/instrument/piano' },
      {
        name: 'Retro synth',
        type: 'tag',
        slug: '/music/instrument/retro-synth',
      },
      { name: 'Synth', type: 'tag', slug: '/music/instrument/synth' },
      { name: 'Ukulele', type: 'tag', slug: '/music/instrument/ukulele' },
      { name: 'Violin', type: 'tag', slug: '/music/instrument/violin' },
    ],
  },
  {
    id: 2,
    name: 'Occasions',
    order: 2,
    emoji: '',
    tag: [
      { name: 'Christmas', type: 'tag', slug: '/music/theme/christmas' },
      { name: 'Halloween', type: 'tag', slug: '/music/theme/halloween' },
      { name: 'Wedding', type: 'tag', slug: '/music/theme/wedding' },
    ],
  },
  {
    id: 3,
    name: 'Genres',
    order: 3,
    emoji: '',
    tag: [
      { name: '8 bit', type: 'tag', slug: '/music/genre/8-bit' },
      { name: 'Ambient', type: 'tag', slug: '/browse/music/ambient' },
      { name: 'Beats', type: 'tag', slug: '/music/genre/beats' },
      { name: 'Chillhop', type: 'tag', slug: '/browse/music/chillhop' },
      { name: 'Classical', type: 'tag', slug: '/music/genre/classical' },
      { name: 'Cinematic', type: 'tag', slug: '/music/genre/cinematic' },
      { name: 'Country', type: 'tag', slug: '/music/genre/country' },
      { name: 'Cyberpunk', type: 'tag', slug: '/music/genre/cyberpunk' },
      { name: 'Dance', type: 'tag', slug: '/music/genre/dance' },
      { name: 'Dubstep', type: 'tag', slug: '/music/genre/dubstep' },
      { name: 'EDM', type: 'tag', slug: '/music/genre/edm' },
      { name: 'Electronic', type: 'tag', slug: '/browse/music/electronic' },
      { name: 'Elevator', type: 'tag', slug: '/music/genre/elevator' },
      { name: 'Epic', type: 'tag', slug: '/browse/music/epic' },
      { name: 'Folk', type: 'tag', slug: '/music/genre/folk' },
      { name: 'Hip Hop', type: 'tag', slug: '/browse/music/hip-hop-beats' },
      { name: 'House', type: 'tag', slug: '/music/genre/house' },
      { name: 'Hype', type: 'tag', slug: '/music/mood/hype' },
      { name: 'Indie', type: 'tag', slug: '/browse/music/indie' },
      { name: 'Jazz', type: 'tag', slug: '/music/genre/jazz' },
      { name: 'Lofi', type: 'tag', slug: '/browse/music/lo-fi-beats' },
      { name: 'Metal', type: 'tag', slug: '/music/genre/metal' },
      { name: 'Pop', type: 'tag', slug: '/browse/music/pop' },
      { name: 'Punk', type: 'tag', slug: '/music/genre/punk' },
      { name: 'Rap', type: 'tag', slug: '/music/tag/rap' },
      { name: 'Reggae', type: 'tag', slug: '/music/genre/reggae' },
      { name: 'Rock', type: 'tag', slug: '/browse/music/rocky' },
      { name: 'Synthwave', type: 'tag', slug: '/music/genre/synthwave' },
      { name: 'Techno', type: 'tag', slug: '/music/genre/techno' },
      { name: 'Trap', type: 'tag', slug: '/browse/music/trap' },
    ],
  },
  {
    id: 4,
    name: 'Theme',
    order: 4,
    emoji: '',
    tag: [
      { name: 'Action', type: 'tag', slug: '/music/theme/action' },
      { name: 'Battle', type: 'tag', slug: '/music/theme/battle' },
      { name: 'Business', type: 'tag', slug: '/browse/music/business' },
      {
        name: 'Cartoon background',
        type: 'tag',
        slug: '/music/tag/cartoon-background',
      },
      { name: 'Christian', type: 'tag', slug: '/music/theme/christian' },
      { name: 'Church', type: 'tag', slug: '/music/tag/church' },
      { name: 'Commercial', type: 'tag', slug: '/music/theme/commercial' },
      { name: 'Cooking', type: 'tag', slug: '/music/theme/cooking' },
      { name: 'Corporate', type: 'tag', slug: '/music/theme/corporate' },
      { name: 'Documentary', type: 'tag', slug: '/music/theme/documentary' },
      { name: 'Drone', type: 'tag', slug: '/music/tag/drone' },
      { name: 'Fantasy', type: 'tag', slug: '/music/theme/fantasy' },
      { name: 'Fitness', type: 'tag', slug: '/music/theme/fitness' },
      { name: 'Game', type: 'tag', slug: '/music/tag/game' },
      { name: 'Holiday', type: 'tag', slug: '/music/theme/holiday' },
      { name: 'Horror', type: 'tag', slug: '/music/mood/horror' },
      { name: 'Jingle', type: 'tag', slug: '/music/tag/jingle' },
      { name: 'Meditation', type: 'tag', slug: '/music/theme/meditation' },
      {
        name: 'Nature documentary',
        type: 'tag',
        slug: '/music/theme/nature-documentary',
      },
      { name: 'News', type: 'tag', slug: '/music/tag/news' },
      { name: 'Retro', type: 'tag', slug: '/music/tag/retro' },
      { name: 'Space', type: 'tag', slug: '/music/theme/space' },
      { name: 'Sports', type: 'tag', slug: '/music/theme/sports' },
      { name: 'Traditional', type: 'tag', slug: '/music/tag/traditional' },
      { name: 'Trailer', type: 'tag', slug: '/music/tag/trailer' },
      { name: 'Travel', type: 'tag', slug: '/browse/music/travel' },
      { name: 'Vlog', type: 'tag', slug: '/browse/music/vlog' },
      { name: 'Western', type: 'tag', slug: '/music/theme/western' },
      { name: 'Workout', type: 'tag', slug: '/music/theme/workout' },
      { name: 'Worship', type: 'tag', slug: '/music/tag/worship' },
      { name: 'Yoga', type: 'tag', slug: '/music/theme/yoga' },
    ],
  },
  {
    id: 5,
    name: 'Eras',
    order: 5,
    emoji: '',
    tag: [
      { name: 'Medieval', type: 'tag', slug: '/music/tag/medieval' },
      { name: '1920s', type: 'tag', slug: '/music/tag/1920s' },
      { name: '50s', type: 'tag', slug: '/music/tag/50s' },
      { name: '60s', type: 'tag', slug: '/music/tag/60s' },
      { name: '70s', type: 'tag', slug: '/music/tag/70s' },
      { name: '80s', type: 'tag', slug: '/music/tag/80s' },
      { name: '90s', type: 'tag', slug: '/music/tag/90s' },
    ],
  },
  {
    id: 6,
    name: 'Global',
    order: 6,
    emoji: '',
    tag: [
      { name: 'African', type: 'tag', slug: '/music/tag/african' },
      { name: 'Arabic', type: 'tag', slug: '/music/tag/arabic' },
      { name: 'Chinese', type: 'tag', slug: '/music/tag/chinese' },
      { name: 'Indian', type: 'tag', slug: '/music/tag/indian' },
      { name: 'Italian', type: 'tag', slug: '/music/tag/italian' },
      { name: 'Irish', type: 'tag', slug: '/music/tag/irish' },
      { name: 'Japanese', type: 'tag', slug: '/music/tag/japanese' },
      { name: 'Latin', type: 'tag', slug: '/music/genre/latin' },
      { name: 'Spanish', type: 'tag', slug: '/music/tag/spanish' },
    ],
  },
];
