export { SkeletonFragment } from '@/components/ui/skeleton';

interface SkeletonProps extends React.HTMLAttributes<HTMLSpanElement> {
  items?: number;
}

/**
 * @deprecated Use ui/skeleton instead
 */
export const Skeleton = ({ items = 1, ...props }: SkeletonProps) => {
  return Array.from({ length: items }).map((_, i) => (
    <span
      // eslint-disable-next-line react/no-array-index-key, @eslint-react/no-array-index-key
      key={i}
      {...props}
    />
  ));
};
