// import { keepPreviousData, useQuery } from '@tanstack/react-query';
// import axios from 'axios';

import {
  staticComprehensiveListingsMusic,
  staticComprehensiveListingsSFX,
} from '@/fixtures/staticComprehensiveListings';
// import { useHypertune } from '@/generated/hypertune.react';

export interface Tag {
  slug: string;
  name: string;
}

export interface Category {
  id: number;
  name: string;
  emoji?: string;
  order?: number;
  tag: Tag[];
}

// TODO: Uncomment and use API endpoint when backend is ready
// export const getComprehensiveListings = async (variant: 'sfx' | 'music') => {
//   const endpoint =
//     variant === 'sfx'
//       ? '/page/sfx/comprehensivelistings'
//       : '/page/tag/comprehensivelistings';

//   const res = await axios.get<Category[]>(endpoint, {
//     baseURL: process.env.NEXT_PUBLIC_API,
//     withCredentials: true,
//   });

//   if (res.status !== 200) {
//     throw new Error(
//       res.statusText ??
//         `Failed to fetch comprehensive listings, status code ${res.status}`,
//     );
//   }

//   return res.data;
// };

// eslint-disable-next-line @eslint-react/hooks-extra/no-redundant-custom-hook
const useComprehensiveListings = (
  initialData?: Category[],
  variant: 'sfx' | 'music' = 'sfx',
) => {
  // Temporarily return static data only
  return {
    data:
      variant === 'sfx'
        ? staticComprehensiveListingsSFX
        : staticComprehensiveListingsMusic,
    isLoading: false,
    error: null,
  };

  // TODO: Uncomment when backend API is ready
  // const hypertune = useHypertune();

  // const isDynamicComprehensiveListingEnabled =
  //   hypertune.dynamicComprehensiveListing({ fallback: false });

  // const query = useQuery({
  //   queryKey: ['comprehensiveListings', variant],
  //   queryFn: () => getComprehensiveListings(variant),
  //   refetchOnWindowFocus: false,
  //   initialData,
  //   placeholderData: keepPreviousData,
  //   enabled: isDynamicComprehensiveListingEnabled,
  // });

  // if (!isDynamicComprehensiveListingEnabled) {
  //   return {
  //     data:
  //       variant === 'sfx'
  //         ? staticComprehensiveListingsSFX
  //         : staticComprehensiveListingsMusic,
  //     isLoading: false,
  //     error: null,
  //   };
  // }

  // return query;
};

export default useComprehensiveListings;
