import { clsx } from 'clsx';
import { motion } from 'framer-motion';
import { ArrowLeft } from 'lucide-react';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import {
  cloneElement,
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { DynamicLink } from '@/components/DynamicLink';
import { Skeleton } from '@/components/Skeleton';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { Foxtrot } from '@/components/ui/typography/heading';
import useComprehensiveListings from '@/hooks/useComprehensiveListings';
import { useIsClient } from '@/hooks/useIsClient';
import useTWScreens from '@/hooks/useTWScreens';
import { cn } from '@/lib/utils';
import { useTracking } from '@/providers/TrackingProvider';
import { UserStateContext } from '@/providers/UserStateProvider';

import type { Category as ComprehensiveListingCategory } from '@/hooks/useComprehensiveListings';
import type { HTMLAttributes, ReactElement } from 'react';

const skeletonWidths = [...Array(20)].map(
  () => `${Math.floor(Math.random() * 41) + 60}px`,
);

const SkeletonButtons = () => {
  return skeletonWidths.map((width, index) => (
    <Button
      // eslint-disable-next-line @eslint-react/no-array-index-key, react/no-array-index-key
      key={index + width}
      aria-label="Back to categories"
      variant="secondary"
      size="sm"
      asChild
      className="animate-pulse"
      style={{ width }}
    >
      <Skeleton />
    </Button>
  ));
};

interface LayoutProps extends HTMLAttributes<HTMLDivElement> {
  heading: React.ReactNode;
  onlyNewUsers?: boolean;
}

const Layout = forwardRef<HTMLDivElement, LayoutProps>(
  ({ children, heading, onlyNewUsers, ...props }, ref) => {
    const isClient = useIsClient();
    const { userState } = useContext(UserStateContext);
    const { isMd } = useTWScreens();
    const [mobileHeading, setMobileHeading] =
      useState<React.ReactNode>(heading);

    const hideCategories = !!onlyNewUsers && !!userState.hasLoggedIn;

    return (
      <div
        ref={ref}
        className={clsx(
          'w-full p-container flex flex-col gap-4 @container/comprehensive-listings',
          !isClient && '[.initial-logged-in_&]:hidden',
          hideCategories && isClient && 'hidden',
        )}
        {...props}
      >
        <Foxtrot asChild className="~h-5/6 flex flex-row gap-2">
          <h2>{!isMd ? mobileHeading : heading}</h2>
        </Foxtrot>
        {!isMd
          ? // eslint-disable-next-line @eslint-react/no-clone-element
            cloneElement(children as ReactElement, {
              onHeadingChange: setMobileHeading,
            })
          : children}
      </div>
    );
  },
);

Layout.displayName = 'Layout';

interface ComprehensiveListingsProps {
  onlyNewUsers?: boolean;
  initialData?: ComprehensiveListingCategory[];
  variant: 'sfx' | 'music';
}

const MusicListings = ({
  data,
  onHeadingChange,
}: {
  data: ComprehensiveListingCategory[];
  onHeadingChange?: (heading: React.ReactNode) => void;
}) => {
  const { initializeTracking } = useTracking();
  const pathname = usePathname();
  const [selectedCategory, setSelectedCategory] = useState<
    string | undefined
  >();

  const router = useRouter();
  const { isMd } = useTWScreens();

  // Reset selected category on route change
  useEffect(() => {
    // eslint-disable-next-line @eslint-react/hooks-extra/no-direct-set-state-in-use-effect
    setSelectedCategory(undefined);
  }, [router]);

  // Update heading when category changes (mobile only)
  useEffect(() => {
    if (!isMd && onHeadingChange) {
      const heading = (
        <div className="flex items-center gap-2">
          {selectedCategory !== undefined && (
            <Button
              aria-label="Back to categories"
              size="icon-xs"
              borderRadius="full"
              variant="secondary"
              onClick={() => setSelectedCategory(undefined)}
              asChild
            >
              <motion.button
                initial={{ scale: 0.6, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.15 }}
              >
                <ArrowLeft className="text-black dark:text-white fill-none size-4" />
              </motion.button>
            </Button>
          )}
          {selectedCategory ?? 'All Categories'}
        </div>
      );

      onHeadingChange(heading);
    }
  }, [selectedCategory, isMd, onHeadingChange]);

  const totalItems = data.reduce(
    (acc, category) => acc + category.tag.length + 2,
    0,
  );

  const calculateRows = (cols: number) => Math.ceil(totalItems / cols);

  // Mobile view
  if (!isMd) {
    return (
      <div className="flex flex-wrap flex-row gap-2">
        {selectedCategory === undefined ? (
          // Show categories view
          data.map((category) => (
            <Button
              key={category.name}
              variant="secondary"
              size="sm"
              onClick={() => setSelectedCategory(category.name)}
              className="dark:border-gray-dark-200 border-gray-light-200"
            >
              {category.name}
            </Button>
          ))
        ) : (
          // Show tags for selected category
          <div className="w-full">
            <div className="flex flex-wrap gap-2">
              {data
                .find((cat) => cat.name === selectedCategory)
                ?.tag.map((tag) => (
                  <Button
                    key={`${selectedCategory}-${tag.name}`}
                    variant="secondary"
                    size="sm"
                    asChild
                    disabled={pathname === tag.slug}
                  >
                    <DynamicLink
                      href={tag.slug}
                      onClick={() => {
                        void initializeTracking({
                          assetType: 'music',
                          endSlug: tag.slug,
                          content: tag.name,
                          contentType: 'Tag',
                          access: 'Category Tags',
                        });
                      }}
                    >
                      {tag.name}
                    </DynamicLink>
                  </Button>
                ))}
            </div>
          </div>
        )}
      </div>
    );
  }

  // Desktop view
  return (
    <div className="grid gap-x-4 gap-y-2 -mx-2 pt-2">
      <style jsx>{`
        div {
          --cols: 4;
          grid-template-columns: repeat(var(--cols), 1fr);
          grid-auto-flow: column;
          grid-template-rows: repeat(${calculateRows(4)}, min-content);
        }
        @container comprehensive-listings (min-width: 1200px) {
          div {
            --cols: 5;
            grid-template-rows: repeat(${calculateRows(5)}, min-content);
          }
        }
        @container comprehensive-listings (min-width: 1440px) {
          div {
            --cols: 6;
            grid-template-rows: repeat(${calculateRows(6)}, min-content);
          }
        }
      `}</style>
      {data.flatMap((category) => [
        <Separator
          key={`sep-${category.name}`}
          className="first-of-type:hidden self-center"
        />,
        <Foxtrot
          asChild
          key={`heading-${category.name}`}
          className="px-2 !text-base"
        >
          <h4>{category.name}</h4>
        </Foxtrot>,
        ...category.tag.map((tag) => (
          <Button
            key={`${category.name}-${tag.name}`}
            variant="transparent"
            size="sm"
            asChild
            disabled={pathname === tag.slug}
            className="w-fit h-7 px-2"
            borderRadius="full"
          >
            <DynamicLink
              href={tag.slug}
              onClick={() => {
                void initializeTracking({
                  assetType: 'music',
                  endSlug: tag.slug,
                  content: tag.name,
                  contentType: 'Tag',
                  access: 'Category Tags',
                });
              }}
            >
              {tag.name}
            </DynamicLink>
          </Button>
        )),
      ])}
    </div>
  );
};

interface SFXListingsProps {
  data: ComprehensiveListingCategory[];
  onHeadingChange: (heading: React.ReactNode) => void;
}

const SFXListings = ({ data, onHeadingChange }: SFXListingsProps) => {
  const [visibleTags, setVisibleTags] = useState<string | undefined>(undefined);
  const categoryRef = useRef<HTMLDivElement | null>(null);
  const router = useRouter();
  const pathname = usePathname();
  const { initializeTracking } = useTracking();

  const handleCategory = (title: string) => {
    categoryRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });

    setVisibleTags(title);
  };

  const resetCategories = () => {
    // eslint-disable-next-line @eslint-react/hooks-extra/no-direct-set-state-in-use-effect
    setVisibleTags(undefined);
  };

  useEffect(resetCategories, [router]);

  // Update parent's heading whenever visibleTags changes
  useEffect(() => {
    const heading = (
      <div className="flex items-center gap-2">
        {visibleTags !== undefined && (
          <Button
            aria-label="Back to categories"
            size="icon-xs"
            borderRadius="full"
            variant="secondary"
            onClick={resetCategories}
            asChild
          >
            <motion.button
              initial={{ scale: 0.6, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.15 }}
            >
              <ArrowLeft className="text-black dark:text-white fill-none size-4" />
            </motion.button>
          </Button>
        )}
        {visibleTags ?? 'Popular categories'}
      </div>
    );

    onHeadingChange(heading);
  }, [visibleTags, onHeadingChange]);

  return (
    <div className="flex flex-wrap flex-row gap-2" ref={categoryRef}>
      {data?.map((category) => {
        return (
          <div
            className={cn(
              visibleTags === undefined || visibleTags === category.name
                ? 'visible'
                : 'hidden',
            )}
            key={category.name}
          >
            <Button
              variant="secondary"
              size="sm"
              onClick={() => handleCategory(category.name)}
              className={cn(
                visibleTags === undefined ? 'visible' : 'hidden',
                'dark:border-gray-dark-200 border-gray-light-200',
              )}
            >
              <span className="text-xs">{category.emoji}</span>
              {category.name}
            </Button>
            <div
              className={cn(
                'flex flex-row flex-wrap gap-2',
                visibleTags === category.name ? 'visible' : 'hidden',
              )}
            >
              {category.tag.map((tag) => {
                return (
                  <Button
                    variant="secondary"
                    size="sm"
                    asChild
                    disabled={pathname === tag.slug}
                  >
                    <DynamicLink
                      key={category.name + tag.name}
                      href={tag.slug}
                      onClick={() => {
                        void initializeTracking({
                          assetType: 'sfx',
                          endSlug: tag.slug,
                          content: tag.name,
                          contentType: 'Tag',
                          access: 'Category Tags',
                        });
                      }}
                    >
                      {tag.name}
                    </DynamicLink>
                  </Button>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ComprehensiveListings = ({
  onlyNewUsers,
  initialData,
  variant,
}: ComprehensiveListingsProps) => {
  const { data, isLoading, error } = useComprehensiveListings(
    initialData,
    variant,
  );

  const defaultHeading =
    variant === 'sfx' ? 'Popular categories' : 'All Categories';

  const [heading, setHeading] = useState<React.ReactNode>(defaultHeading);

  if (!data && isLoading) {
    return (
      <Layout heading={defaultHeading} onlyNewUsers={onlyNewUsers}>
        <div className="flex flex-wrap flex-row gap-2">
          <SkeletonButtons />
        </div>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout heading={defaultHeading} onlyNewUsers={onlyNewUsers}>
        <div>
          <p className="text-red">
            {/* TODO: Add error message once backend is plugged in */}
            {/* {error?.message ?? 'Unable to load categories'} */}
            Unable to load categories
          </p>
        </div>
      </Layout>
    );
  }

  if (!data) {
    return null;
  }

  if (variant === 'music') {
    return (
      <Layout heading={defaultHeading} onlyNewUsers={onlyNewUsers}>
        <MusicListings
          data={data}
          onHeadingChange={(newHeading) => setHeading(newHeading)}
        />
      </Layout>
    );
  }

  return (
    <Layout heading={heading} onlyNewUsers={onlyNewUsers}>
      <SFXListings data={data} onHeadingChange={setHeading} />
    </Layout>
  );
};

export default ComprehensiveListings;
